<template>
  <el-dialog
    v-model="dialogVisible"
    title="提示"
    width="30%"
    :before-close="handleClose"
  >
    <span>这是一段信息</span>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button
          type="primary"
          @click="dialogVisible = false"
        >确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>
<script>
import { defineComponent } from 'vue';
export default defineComponent({
    props:{
        list: {
            type:Array,
            default: ()=>[]
        }
    },
    setup() {
        return{};
    }
    
});
</script>